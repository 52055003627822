import React from 'react';
import Helmet from 'react-helmet';

import logo from './viident-logo-transparent.png';

import './App.css';

import { Link } from 'react-router-dom';

function Home() {
    return (
      <div className="App">
              <Helmet>
                <meta charSet="utf-8" />
                <title>Viident</title>
                <link rel="canonical" href="http://mysite.com/example" />
                <meta name="description" content="Viident publishing group information" />
            </Helmet>
            <div className="App-main">
              <img src={logo} className="App-logo" alt="logo" />
              <p className="App-main-text">
                Viident means <span style={{fontStyle: 'italic', display: 'inline-block', verticalAlign: 'top'}}>see the possibilities.</span><br/>
                At Viident Publishing we are dedicated to helping readers <span className="break-word">see the possibilities in every aspect of their lives.<br/></span>
                Three imprints.<br/>
                Two hundred authors.<br/>
                One thousand titles.<br/>
                One hundred million books sold.<br/>
                Unlimited possibilities.
              </p>
            </div>
              <footer className="App-footer">
                <p style={{fontStyle: 'italic'}}>Discover our life-altering books at...</p>
                <a className="App-link" href="https://www.bluesparrowbooks.org">
                  <p>www.bluesparrowbooks.org</p>
                </a>
                <a className="App-link" href="https://www.wellspringbooks.org">
                  <p>www.wellspringbooks.org</p>
                </a>
                <a className="App-link" href="https://www.servantbooks.org">
                  <p>www.servantbooks.org</p>
                </a>
                <br/>
                <Link to="/careers" className="App-link">Careers</Link>
                <br/>
                <p className="quote-text">
                  "Books change our lives, because we become the books we read."
                </p>
                <p>
                  Matthew Kelly
                </p>
              </footer>
      </div>
    );
  }
  
  export default Home;