import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';
import logo from './viident-logo-transparent.png';

function Operations() {
  return (
    <div className="App">
            <div className="App-main">
                <img src={logo} className="App-logo" alt="logo" />
                <p className="App-main-text">
                    Operations Coordinator
                </p>

                <p>Viident Publishing Inc.<br/>
                Cincinnati/Northern Kentucky<br/>
                Full-Time</p>

                <br/>

                <h3>About the Role</h3>

                <p>The Operations Coordinator is responsible for managing the publishing process, which is the step-by-step journey each title takes from the creation of the manuscript to the release of the finished product and beyond. There are a variety of professionals responsible for each discipline along the way, the Operations Coordinator ensures a smooth production process for Viident’s print, digital, and audio titles.

                <br/>
                <br/>

                This person will collaborate with design, editorial, production, and marketing, shepherding titles through all stages of publishing process. The goal is to create world-class books that are delivered accurately and on schedule.

                <br/>
                <br/>

                This position is an integral part of a small and committed team. A “whatever it takes” mentality combined with a “no task is too big or too small” attitude is required to succeed in this role.</p>

                <br/>

                <h4>Responsibilities include:</h4>
                <p>
                    Managing all aspects of the publishing process
                    <br/><br/>
                    Ensuring deadlines are met by individual contributors and teams
                    <br/><br/>
                    Gather and distribute metadata for internal and external use
                    <br/><br/>
                    Communicating with manufacturers and suppliers
                    <br/><br/>
                    Maintaining the Master Publishing Schedule
                </p>

                <h4>Qualifications:</h4>
                <ul>
                    <li>Project Management</li>
                    <li>A passion for implementing systems</li>
                    <li>Ability to adhere to budgets and allocate resources</li>
                    <li>Familiarity (or interest in learning about publishing)</li>
                    <li>Meticulous attention to detail</li>
                    <li>Exemplary organizational skills</li>
                    <li>Capacity to manage multiple (and often competing) deadlines</li>
                    <li>Outstanding work-ethic</li>
                    <li>Sense of humor</li>
                    <li>Love of books and a passion for reading </li>
                    <li>Desire to add enormous value by anticipating needs and solving problems.</li>
                </ul>
                
                <p>Viident creates content that helps people become the-best-version-of-themselves. If you are a qualified candidate interested in getting paid to grow professionally, make a difference, add tremendous value to our team, and change lives, we look forward to hearing from you.</p>
                
                <p>The position is a Monday-Friday in-office role, occasionally requiring availability outside of normal business hours.</p>

                <br/>

                <h4>Compensation Package:</h4>
                <p>Salary $50,000 to $70,000 + Full Health Care Benefits + 3% 401k Match</p>
                <p>Job Type: Full-Time</p>
                <p>Pay: $50,000.00 - $70,000.00 per year</p>
                <p>Benefits: 401(k) matching, Health Insurance</p>
                <p>Schedule: Monday to Friday</p>
                <p>Work Location: In person</p>

                <h4>If you are interested, please email your cover letter and resume to careers@viident.com.</h4>
            </div>
            <br/>
            <br/>
            <footer className="App-footer">
              <p style={{fontStyle: 'italic'}}>Discover our life-altering books at...</p>
              <a className="App-link" href="https://www.bluesparrowbooks.org">
                <p>www.bluesparrowbooks.org</p>
              </a>
              <a className="App-link" href="https://www.wellspringbooks.org">
                <p>www.wellspringbooks.org</p>
              </a>
              <a className="App-link" href="https://www.servantbooks.org">
                <p>www.servantbooks.org</p>
              </a>
              <br/>
              <Link to="/careers" className="App-link">Back to Careers</Link>
              <br/>
              <p className="quote-text">
                "Books change our lives, because we become the books we read."
              </p>
              <p>
                Matthew Kelly
              </p>
            </footer>
    </div>
  );
}

export default Operations;