import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';
import logo from './viident-logo-transparent.png';

function Editorial() {
  return (
    <div className="App">
            <div className="App-main">
                <img src={logo} className="App-logo" alt="logo" />
                <p className="App-main-text">
                    Assistant Editor
                </p>

                <p>Viident Publishing Inc.<br/>
                Cincinnati/Northern Kentucky<br/>
                Full-Time</p>

                <h3>About the Role</h3>

                <p>The Assistant Editor or Editorial Assistant will support the CEO and other editorial team members in all phases of the review and editing process of books published under Viident and its various imprints. This person will work closely with authors, production/operations, and designers to ensure world-class titles are delivered accurately and on schedule.

                <br/>
                <br/>

                This position is an integral part of a small and committed team. A “whatever it takes” mentality combined with a “no task is too big or too small” attitude is required to succeed in this role. The position consists of a 5-day in-office schedule, Monday-Friday, occasionally requiring availability outside of normal business hours.</p>

                <br/>

                <h4>Responsibilities include:</h4>
                <p>
                    Reviewing and evaluating manuscripts for acquisition
                    <br/><br/>
                    Copyediting and proofreading necessary copy and ancillary materials
                    <br/><br/>
                    Editing throughout all stages of the publishing process (developmental, line, etc.)
                    <br/><br/>
                    Managing metadata and uploading content to Amazon, Barnes & Noble, BookBaby, and Ingram
                    <br/><br/>
                    Preparing manuscripts for the design/typesetting process
                    <br/><br/>
                    Writing copy for ancillary or promo materials
                    <br/><br/>
                    Frequent communication with our CEO and outside agents and authors, organizing meetings where required
                    <br/><br/>
                    Expense/invoice tracking and reporting
                    <br/><br/>
                    Assisting in all aspects of the production process. This person may also be responsible for vetting freelance copyeditors/proofreaders and other general administrative duties where necessary.
                </p>

                <h4>Qualifications:</h4>
                <ul>
                    <li>A passion for reading and books</li>
                    <li>Previous experience in copyediting and proofreading</li>
                    <li>Meticulous attention to detail and exemplary organizational skills</li>
                    <li>Excellent written and verbal communication skills</li>
                    <li>Familiarity with the book production process preferred</li>
                    <li>Desire to add enormous value to a growing independent publishing company</li>
                    <li>Capacity to manage multiple (and often competing) deadlines</li>
                    <li>Enthusiasm, humility, self-awareness, and ability to remain calm under pressure</li>
                    <li>Fully skilled in use of Word with Track Changes for copyediting manuscripts</li>
                </ul>

                <p>Viident creates content that helps people become the-best-version-of-themselves. If you are a qualified candidate interested in getting paid to grow professionally, make a difference, add tremendous value to our team, and change lives, we look forward to hearing from you.</p>

                <br/>

                <h4>Compensation Package:</h4>
                <p>Salary $55,000 + Full Health Care Benefits + 3% 401k Match</p>
                <p>Job Type: Full-Time</p>
                <p>Pay: $55,000.00 per year</p>
                <p>Benefits: 401(k) matching, Health Insurance</p>
                <p>Schedule: Monday to Friday</p>
                <p>Work Location: In person</p>

                <h4>If you are interested, please email your cover letter and resume to careers@viident.com.</h4>
            </div>
            <br/>
            <br/>
            <footer className="App-footer">
              <p style={{fontStyle: 'italic'}}>Discover our life-altering books at...</p>
              <a className="App-link" href="https://www.bluesparrowbooks.org">
                <p>www.bluesparrowbooks.org</p>
              </a>
              <a className="App-link" href="https://www.wellspringbooks.org">
                <p>www.wellspringbooks.org</p>
              </a>
              <a className="App-link" href="https://www.servantbooks.org">
                <p>www.servantbooks.org</p>
              </a>
              <br/>
              <Link to="/careers" className="App-link">Back to Careers</Link>
              <br/>
              <p className="quote-text">
                "Books change our lives, because we become the books we read."
              </p>
              <p>
                Matthew Kelly
              </p>
            </footer>
    </div>
  );
}

export default Editorial;