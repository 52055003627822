import React from 'react';
import { Link } from 'react-router-dom';
import './App.css';
import logo from './viident-logo-transparent.png';

function Careers() {
  return (
    <div className="App">
            <div className="App-main">
                <img src={logo} className="App-logo" alt="logo" />
                <p className="App-main-text">
                    Careers
                </p>
                <div className="App-subcontent">
                    <Link to="/careers/operations-coordinator" className="App-link">Operations Coordinator</Link>
                    <br/>
                    <Link to="/careers/assistant-editor" className="App-link">Assistant Editor</Link>
                </div>

                <h4>About Viident</h4>

                <p>Viident is the largest independent publisher in the United States, dedicated to publishing non-fiction and fiction in the following categories: general interest, spirituality, personal development, and business for readers of all ages, in all printed, digital, and audio formats. </p>

                <br/>

                <p>Viident was founded in 1993 by <i>New York Times</i> bestseller author Matthew Kelly. We are uniquely positioned to partner with authors because we have unique distribution models and have an intimate understanding of the author perspective.</p>
            </div>
            <br/>
            <br/>
            <footer className="App-footer">
              <p style={{fontStyle: 'italic'}}>Discover our life-altering books at...</p>
              <a className="App-link" href="https://www.bluesparrowbooks.org">
                <p>www.bluesparrowbooks.org</p>
              </a>
              <a className="App-link" href="https://www.wellspringbooks.org">
                <p>www.wellspringbooks.org</p>
              </a>
              <a className="App-link" href="https://www.servantbooks.org">
                <p>www.servantbooks.org</p>
              </a>
              <br/>
              <Link to="/" className="App-link">Back to Home</Link>
              <br/>
              <p className="quote-text">
                "Books change our lives, because we become the books we read."
              </p>
              <p>
                Matthew Kelly
              </p>
            </footer>
    </div>
  );
}

export default Careers;