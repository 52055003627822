import logo from './viident-logo-transparent.png';

import './App.css';

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Home from './Home';
import Careers from './Careers';
import Editorial from './Editorial';
import Operations from './Operations';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/careers" element={<Careers />}/>
        <Route path="/careers/operations-coordinator" element={<Operations />}/>
        <Route path="/careers/assistant-editor" element={<Editorial />}/>
      </Routes>
    </Router>
  );
}

export default App;
